<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <PacienteInfo
                        v-model="form.state.paciente"
                        :isReadOnly="form.state.id ? true : false"
                    ></PacienteInfo>
                    <DoctorInfo
                        v-model="form.state.doctor"
                        :isReadOnly="form.state.id ? true : false"
                    ></DoctorInfo>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Institucion</label>
                                    <div class="col-md-9">
                                        <InstitucionAutocomplete
                                            :class="{ 'is-invalid': form.v.institucion?.$error }"
                                            v-model="form.state.institucion"
                                            :disabled="form.disabled.value"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="form.v.institucion?.$errors.length"
                                        >
                                            {{ form.v.institucion.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Examen</label>
                                    <div
                                        class="col-md-9"
                                        :class="{ 'is-invalid': form.v.examen?.$errors.length }"
                                    >
                                        <Autocomplete
                                            :debounce="1200"
                                            @input="getExamenes"
                                            :items="examenes"
                                            :displayItem="
                                                (item) =>
                                                    typeof item === 'string' ? item : item.name
                                            "
                                            placeholder="Buscar un examen"
                                            @onSelect="form.onSelectExamen"
                                            ref="examenAutocomplete"
                                            :disabled="form.disabled.value"
                                        ></Autocomplete>
                                    </div>
                                    <div
                                        class="invalid-feedback"
                                        v-if="form.v.examen?.$errors.length"
                                    >
                                        {{ form.v.examen.$errors[0].$message }}
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Fecha</label>
                                    <div class="col-md-9">
                                        <DateInput
                                            v-model="form.state.date"
                                            :onlyRead="form.disabled.value"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Estado</label>
                                    <div class="col-md-9">
                                        <SelectInputSimpleState v-model="form.state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12" v-if="isReady && form.state.id && form.state.documentStatus < 2">
                <ResultadoFiles
                    v-model="form.state.files"
                    :idResultado="form.state.id"
                    v-if="form.examenSelected"
                    @onUpdate="form.handleOrderFiles"
                    @onRemove="form.handleRemoveFile"
                    :readOnly="form.state.documentStatus > 1"
                    :selectable="selectable"
                />
            </div>
            <div class="col-sm-12" v-if="isReady && form.state.documentStatus > 1">
                <RenderedFiles
                    :files="form.state.files.filter((i) => i.selected === true)"
                    :disabled="form.disabled.value"
                    @onRemoveRender="form.handleRemoveRender"
                />
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-warning ms-3 mb-1"
                                @click="form.handleAction('reset-renders')"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '2'
                                "
                            >
                                <i class="far fa-edit"></i>
                                Editar
                            </div>
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="vfm.open(finishModalId)"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '2'
                                "
                            >
                                <i class="far fa-paper-plane"></i>
                                Publicar
                            </div>
                            <!-- <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleCreateCopy"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '3'
                                "
                            >
                                <i class="far fa-copy"></i>
                                Generar Copia
                            </div> -->
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="form.handleAction('resend')"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '3'
                                "
                            >
                                <i class="far fa-file-import"></i>
                                Reenviar
                            </div>
                            <div
                                class="btn btn-success ms-3 mb-1"
                                @click="form.handleAction('merge_close')"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '1' &&
                                    form.isUpdate
                                "
                            >
                                <i class="far fa-file-import"></i>
                                Finalizar
                            </div>
                            <!-- <div
                                class="btn btn-success ms-3 mb-1"
                                @click="form.handleFinishDocument"
                                v-if="
                                    form.state.id &&
                                    form.state.status == 1 &&
                                    form.state.documentStatus === '1' &&
                                    form.isUpdate
                                "
                            >
                                <i class="far fa-file-import"></i>
                                Finalizar
                            </div> -->
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="form.handleAction('save')"
                                v-if="form.state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!form.state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="form.handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!form.state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="form.handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Observaciones
                :observaciones="form.state.observaciones"
                @onAddObservacion="form.handleAddNewObservacion"
                @onDeleteObservacion="form.handledeleteNewObservacion"
            />
            <div
                class="col-sm-12"
                v-if="form.state.id && form.state.status == 1 && form.state.documentStatus === '3'"
            >
                <ResendEmail @onSendEmail="form.handleResend" />
            </div>
        </div>
        <FinalConfirmModal
            :modalId="finishModalId"
            :files="form.state.files.filter((i) => i.selected === true)"
            @onConfirm="handlerConfirmPublish"
        />
        <pre v-if="env === 'development'">{{ JSON.stringify(form.state, null, 4) }}</pre>
        <pre v-if="env === 'development'">{{ form.v }}</pre>
    </WindowLayout>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { defineProps, ref, onMounted } from 'vue';
import { useVfm } from 'vue-final-modal';

import WindowLayout from '@/layouts/Window.layout.vue';

import Autocomplete from '@/components/form/Autocomplete.vue';
import DateInput from '@/components/form/DateInput.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';
import InstitucionAutocomplete from '../Instituciones/components/InstitucionAutocomplete.vue';
import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';
import DoctorInfo from '../Doctores/components/DoctorInfo.vue';
import ResultadoFiles from '../Resultados/components/ResultadoFiles.vue';
import Observaciones from '../Components/Observaciones.vue';
import ResendEmail from '../Resultados/components/resendEmail.vue';
import RenderedFiles from '../Resultados/components/RenderedFiles.vue';

import useResultado from '../Resultados/services/useResultado';
import useExamen from '../Examenes/services/useExamen';
import { useResultadoLegacyForm } from './composables/useResultadoLegacyForm';
import useResultadoFile from '../Resultados/services/useResultadoFile';
import FinalConfirmModal from '../Resultados/components/FinalConfirmModal.vue';

const finishModalId = Symbol('finishModalId');

const vfm = useVfm();

const props = defineProps({
    title: { type: String, default: 'Resultado de Examen' },
    headerTitle: { type: String, default: 'Crear Resultado' },
    urlRead: { type: String, required: false },
    urlCreate: { type: String, required: false },
    urlUpdate: { type: String, required: false },
    item: Object,
});

const isReady = ref(false);
const selectable = ref(false);

const service = useResultado();
const resultadoFileService = useResultadoFile();
const { examenAutocomplete, ...form } = useResultadoLegacyForm({
    ...service,
    ...resultadoFileService,
});

const { examenes, getExamenes } = useExamen();

onMounted(async () => {
    if (props.item) {
        await Promise.all([service.getResultado(props.item.id)]);
        if (service.resultado) {
            form.setState(service.resultado.value);
        }
    }
    form.state.mode = 1;
    isReady.value = true;
});

const handlerConfirmPublish = async () => {
    vfm.close(finishModalId);
    await form.handleAction('publish');
};

const env = process.env.NODE_ENV;
</script>

<style></style>
